import React from 'react';
import PropTypes from 'prop-types';

const InputElement = ({ label, value, onChange, disabled = true, className = '', ...props }) => {
  const handleChange = (e) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <tr>
      <td className="col-3 fw-bold text-center">
        {label}
      </td>
      <td className="col-9">
        <input
          type="text"
          id="vendorId"
          className={`form-control text-center fs-5 ${disabled ? 'bg-light' : ''} ${className}`}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          {...props}
        />
      </td>
    </tr>
  );
};

InputElement.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default InputElement;