import React from "react";
import ReactDOM from "react-dom/client"; 
import App from "./App"; 
import { QueryClientProvider } from 'react-query';
import { QueryClientProvider as TanstackQueryProvider } from '@tanstack/react-query';
import { queryClient, tanstackQueryClient } from './lib/react-query.js';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <TanstackQueryProvider client={tanstackQueryClient}>    
    <QueryClientProvider client={queryClient}>
        <App />
    </QueryClientProvider>
    </TanstackQueryProvider>
);
