import axios from 'axios';
import { SYSTEM_URL } from '../global';
const httpClient = axios.create({
    baseURL: SYSTEM_URL + '/',
});
export const publicAxiosClient = axios.create({
    baseURL: SYSTEM_URL + '/',
});

httpClient.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (!token) {
        return config;
    }
    httpClient.defaults.headers.common.Authorization = `Bearer ${token}`;
    return config;
});
export default httpClient;
