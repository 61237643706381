import { QueryClient as TanstackQueryClient } from '@tanstack/react-query';
import { useQueryClient, QueryClient, QueryCache } from 'react-query';  
 
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime:0,
            retry: false,
            refetchOnWindowFocus: false,
            onError: (err) => {
               console.log(err)
            },
        },
        mutations:{
            
            retry:false,
        }
      
    },

 
});

export const tanstackQueryClient = new TanstackQueryClient({
    defaultOptions: {
        queries: {
            
            retry: false,
            refetchOnWindowFocus: false,

        },
        mutations:{
            retry:false,
        }
    },
});
